import { MAIN_ROUTE_AI_FW } from "./lib/constants";
import { getFrameworkVars, getAuthState } from "@sparky/framework";

function isAvailable(obj: unknown) {
    // display AI Runtime for AIOPS premium tenants with CDL license
    // TODO revisit condition along with SCM pricing and packaging
    const isPremium = !getFrameworkVars().aiops_free;
    const hasCDL = getAuthState()?.instances?.isRunningStatus("logging_service");
    return isPremium && hasCDL;
}

const { NODE_ENV } = process.env;
const env = NODE_ENV || "development";
const isDev = env === "development";
console.log(
    `%c@reporting-n-viz/ai-firewall: ${1.0}`,
    "display: block; font-size: 21px; border: 2px solid teal; border-radius: 8px; padding: 16px; margin: 16px",
);
export default async () => {
    return async () => {
        const { user_preferences_ui, pdf_mode } = getFrameworkVars();
        const isUserPreferencesSupported = Boolean(user_preferences_ui);
        const isPDF = Boolean(pdf_mode);

        return {
            navigation: [
                {
                    key: "insights",
                    path: `/insights`,
                    mergeNav: true,
                    children: [
                        {
                            key: "ai_security",
                            title: "AI Runtime Security",
                            path: MAIN_ROUTE_AI_FW,
                            component: () => import("./components/core/Root"),
                            className: "parent-node",
                            contentClassName: "panwds-tw3" + (isUserPreferencesSupported ? "" : " sparky-dark-only"),
                            mergeNav: true,
                            expandable: false,
                            callAvail: true,
                            hidden: false,
                            avail: isAvailable,
                            noRBACheck: true,
                        },
                    ],
                },
            ],
        };
    };
};
